/* If using CSS */
@import 'bulma/css/bulma.css';
@import '~bulma-divider';
@import url('https://fonts.googleapis.com/css2?family=Faculty+Glyphic&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';

/* ANIMATION CONFIG */
.animate__animated.animate__flipInX {
  --animate-duration: 1.5s;
}

.animate__animated.animate__flipInX {
  --animate-duration: 2s;
}

.animate__animated.animate__zoomIn {
  --animate-duration: 1s;
}

/* Color Variables */
:root {
  --primary-color: #202D5A;
  --secondary-color: #8D1919;
  --blue-neutral-color: #014AAD;
  --blue-bright-color: #57e4ff;
  --grey-color: #FAFAFA;
  --primary-dark-color: #10182F;
  
}

html {
  scroll-behavior: smooth;
}

label {
  font-weight: 300 !important;
  font-size: 15px !important;
}

body {
  margin: 0;
  font-family: 'Faculty Glyphic', sans-serif !important;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

p, .label {
  color: var(--primary-color);
}

input, textarea {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border: 1px solid #999 !important;
}

::placeholder {
  color: #999 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

a {
  font-size: 17px;
}

.navbar, .navbar-menu-container {
  background-color: white;
}

.videobox-title {
  color: var(--primary-color);
}

.testimonial-title {
  color: var(--primary-color);
}

.sticky-box-wrapper, .card-container {
  background-color: white;
  color: var(--primary-color);
  filter: drop-shadow(3px 6px 6px #c2c2c274);
}

.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  background-image: linear-gradient(rgba(0, 0, 0, 0.437), rgba(0, 0, 0, 0.327)), url("/public/images/full-bg.png")
}

.equal-height-columns {
  display: flex;
  align-items: stretch;
}

.equal-height-columns .column .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.sticky-box-desc {
  font-size: 14px;
  line-height: 25px;
}

.sticky-box-wrapper {
  min-height: 250px;
}

.container-carousel {
  margin-top: 70px !important;
}

.testimonial-container, .videobox-container {
  margin-top: 80px;
}

.testimonial-columns-container {
  margin-left: 0;
  margin-bottom: 0;
}

.testimonial-container {
  background-color: var(--grey-color);
  padding-top: 80px;
  padding-bottom: 80px;
}

.testimonial-highlight-text {
  background: rgb(141,25,25);
  background: linear-gradient(90deg, rgba(141,25,25,1) 0%, rgba(33,45,90,1) 100%);
}

.card-long {
  min-height: 260px;
}

.card-short {
  min-height: 170px;
}


.testimonial-text {
  font-size: 13px;
}

.footer-container {
  background-color: var(--primary-dark-color);
}

.footer-link-map, .copyright-text {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.581);
  font-size: 11px;
}

.footer-link-map:hover {
  color: white;
}

.footer-link-container {
  max-width: 600px;
}

#block-contact {
  padding: 10px 25px;
  color: var(--primary-color);

}

.block-white {
  padding: 15px 15px;
  background-color: white;
  color: var(--primary-dark-color);
}

.contact-sub-title {
  color: var(--primary-color);
}

.contact-section {
  background: rgb(0,196,204);
  background: linear-gradient(90deg, rgba(0,196,204,1) 0%, rgba(222,227,240,1) 100%);
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.about-container {
  margin-top: 170px !important;
  margin-bottom: 170px !important;
}

.about-header {
  line-height: normal;
}

.about-header-text-span {
  color: var(--secondary-color);
}

.about-description {
  font-size: 22px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(1000px, 1fr));
  width: 2000px;
  border: 1px solid white;
}

.img-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.cta-button:hover {
  color: var(--blue-neutral-color);
  background-color: var(--grey-color);
}

.custom-contact-box {
  background-color: white;
}


/* Custom responsiveness on MOBILE */
@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  input, textarea {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    border: 1px solid #999 !important;
  }

  ::placeholder {
    color: #999 !important;
  }

  p, .label {
    color: var(--primary-color);
  }
  
  .stickybox-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .sticky-box-wrapper, .card-container {
    background-color: white;
    color: var(--primary-color);
    filter: drop-shadow(3px 6px 6px #c2c2c274);
  }

  .videobox-title {
    color: var(--primary-color);
  }

  .testimonial-title {
    color: var(--primary-color);
  }

  .about-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .about-description {
    font-size: 15px;
  }
  
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: auto;
  }

  .custom-contact-box {
    background-color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    flex-direction: column;
  }

  .input-container {
    display: block !important;
  }

  .field {
    width: 100% !important;
  }

  .email-address-field {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* Custom responsiveness on TABLET */
@media only screen and (min-width: 600px) and (max-width: 1480px) {
  .about-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

